<!--  -->
<template>
  <div class="GetBackPassword">
    <div class="head">
      <h1>账号注册</h1>
      <span></span>
    </div>
    <van-form
      @submit="onSubmit"
      class="login_form flexcolumn"
      style="margin-top: 30px"
    >
      <div class="login_title"></div>
      <van-field
        v-model="form.name"
        name="用户名"
        label=""
        type="text"
        maxlength="4"
        :label-width="30"
        placeholder="请输入姓名"
        :rules="[{ required: true, message: '' }]"
      />
      <van-cell @click="sexShow = true" :title="form.sex"></van-cell>
      <van-popup v-model="sexShow" round position="bottom">
        <van-picker
          title="性别"
          show-toolbar
          value-key="name"
          :columns="sexList"
          @confirm="sexClick"
        />
      </van-popup>
      <van-field
        v-model="form.phonenumber"
        maxlength="11"
        name="手机号"
        label=""
        type="Number"
        :label-width="30"
        placeholder="请输入手机号"
        :rules="[{ validator: validator, message: '' }]"
      />
      <div class="flex" style="width: 100%">
        <van-field
          v-model="form.smscode"
          maxlength="11"
          name="验证码"
          label=""
          type="Number"
          :label-width="30"
          placeholder="请输入验证码"
          :rules="[{ required: true, message: '' }]"
        />
        <div
          style="width: 50%; color: #1989fa; text-align: center"
          @click="
            verification.name == '获取验证码'
              ? verificationClick()
              : $Toast(`请等待${verification.sec}秒后操作`)
          "
        >
          {{ verification.name }}
        </div>
      </div>
      <div
        style="width: 100%; text-align: right; height: 50px; line-height: 50px"
        @click="$router.push('/')"
      >
        去登录?
      </div>
      <!-- <van-field
        v-model="newPassword"
        type="text"
        :label-width="30"
        name="newPassword"
        label=""
        placeholder="请输入新密码"
        :rules="[{ required: true, message: '' }]"
      />
      <van-field
        v-model="newPassword2"
        type="text"
        :label-width="30"
        name="newPassword2"
        label=""
        placeholder="请重复新密码"
        :rules="[{ validator: verifyPassword, message: '两次密码不一致' }]"
      /> -->
      <div style="width: 100%">
        <van-button
          round
          block
          type="info"
          class="myButton"
          native-type="submit"
          style="box-shadow: 0 6px 10px 2px rgba(0, 119, 255, 0.3)"
          >确认</van-button
        >
      </div>
    </van-form>
  </div>
</template>

<script>
import { Register, SendSMSAsync } from "@/api";
export default {
  name: "AddUser",
  components: {},
  data() {
    return {
      username: "",
      identity: "",
      newPassword: "",
      newPassword2: "",
      sexShow: false,
      sexList: [
        { name: "男", type: 1 },
        { name: "女", type: 2 },
      ],
      form: {
        name: "",
        sex: "男",
        phonenumber: "",
        smscode: "",
      },
      verification: {
        name: "获取验证码",
        sec: 60,
      },
      timeFc: "",
    };
  },
  computed: {},
  watch: {},
  methods: {
    async verificationClick() {
      if (/^[1][3,4,5,7,8,9][0-9]{9}$/.test(this.form.phonenumber)) {
        var timeFc = setInterval(() => {
          this.verification.sec -= 1;
          this.verification.name = this.verification.sec + "s";
          if (this.verification.sec <= 0) {
            clearInterval(timeFc);
            this.verification = this.$options.data().verification;
          }
        }, 1000);
        let res = await SendSMSAsync({ phonenumber: this.form.phonenumber });
        this.$Toast(res.Message)
      }else this.$Toast("请输入正确的手机号")
    },
    validator(val) {
      return /^[1][3,4,5,7,8,9][0-9]{9}$/.test(val);
    },
    sexClick(item) {
      console.log(item);
      this.form.sex = item.name;
      this.sexShow = false;
    },
    async onSubmit() {
      let res=await Register({...this.form});
      this.$Toast(res.Message);
      this.form=this.$options.data().form;
    },
    verifyPassword(val) {
      return false;
      //   debugger;
    },
  },
  created() {},
  mounted() {},
};
</script>
<style lang='scss' scoped>
.GetBackPassword {
  width: 100%;
  padding: 200px 60px;
  min-height: 100vh;
  background-color: white;
  h1 {
    font-size: 60px;
    font-weight: bold;
    letter-spacing: 8px;
  }
  .head {
    span {
      width: 45px;
      display: block;
      height: 6px;
      background-color: #000;
    }
  }
  /deep/ .van-cell {
    padding-top: 15px;
    padding-bottom: 15px;
  }
}
</style>